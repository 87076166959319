<template>
	<div>
		<div class="match_item content flex">
			<img :src="info.cover" class="match_image">
			<div class="match_content">
				<div class="pointer_s  match_btn_un flex-center" v-if="info.active_state==2">
					未开始
				</div>
				<div class="pointer_s match_btn flex-center" @click="toSign" v-if="info.active_state==3">
					立即报名
				</div>
				<div class="pointer_s match_btn_un flex-center" v-else>
					{{ info.active_state == 4 ? "报名已结束" : info.active_state == 5 ? "活动已开始" : "活动已结束" }}
				</div>

				<div class="match_name">
					<div style="width: 800px;line-height: 30px" class="two-line">
						{{ info.name }}
					</div>
				</div>
				<div class="flex-space-between" style="align-items: flex-end">
					<div class="contentM">
						<div class="content_text">比赛地点：{{ info.province + info.city + info.address }}</div>
						<div class="content_text">报名时间：{{ info.sign_start }}-{{ info.sign_end }}</div>
						<div class="content_text">比赛时间：{{ info.start_time }}-{{ info.end_time }}</div>
					</div>
					<div class="flex">
						<img src="../../../assets/image/img/view.png" class="img_icon"><span
						class="img_txt">浏览 {{ info.browses }}</span>
						<div class="line_shu"></div>
						<img src="../../../assets/image/img/edit.png" class="img_icon"><span class="img_txt">报名 0 </span>
					</div>
				</div>
			</div>
		</div>


		<div class="rich_body content">
			<div class="sign_body_S flex-direction-column">
				<div class="sign_header flex">
					<div class="line_shu2"></div>
					赛事详情
				</div>
				<div class="rich" style="display: inline-block;margin-top: 20px" v-html="info.detail"></div>
			</div>
		</div>


		<el-dialog
			:show-close="false"
			:append-to-body="true"
			:visible.sync="dialogVisible"
			width="360px">
			<div class="sign_image">
				<div class="sing_text">
					立即报名
				</div>
				<div class="sing_text2">
					扫码进入快速报名
				</div>
				<div v-loading="loading_img" v-if="dialogVisible">
					<img :src="info.share" @load="handleImageLoad" class="er_png">
				</div>
				<img src="../../../assets/image/img/close.png" class="close_png" @click="dialogVisible=false">

			</div>
		</el-dialog>

	</div>
</template>

<script>
import {getTrainInfo} from "@/service/api";

export default {
	name: "matchSignUp",
	data() {
		return {
			dialogVisible: false,
			info: {
				name: '',
				share: '',
				province: '',
				city: '',
				sign_start: '',
				sign_end: '',
				start_time: '',
				end_time: '',
				detail: '',
			},
			loading_img: false,
		}
	},
	mounted() {
		let info = {}
		if (this.$route.params.info) {
			sessionStorage.setItem('params', this.$route.params.info)
			info = JSON.parse(this.$route.params.info)
		} else {
			info = JSON.parse(sessionStorage.getItem('params'))
		}
		this.getData(info.id)
	},
	methods: {
		handleImageLoad() {
			this.loading_img = false
		},
		getData(id) {
			getTrainInfo({
				id: id,
			}).then((res) => {
				this.info = res.msg
			})
		},
		toSign() {
			this.dialogVisible = true
			this.loading_img = true
		},
	}
}
</script>

<style scoped lang="less">
.match_item {
	height: 230px;
	background: #FFFFFF;

	.match_image {
		width: 288px;
		height: 180px;
		border-radius: 5px;
		object-fit: cover;
		margin-left: 25px;
	}

	.match_content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 180px;
		margin-left: 18px;

		.match_name {
			width: 980px;
			font-size: 22px;
			color: #333333;
			margin-left: 18px;
		}

		.contentM {
			margin-left: 20px;

			.content_text {
				height: 27px;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				line-height: 27px;
			}
		}

		.img_icon {
			width: 20px;
			height: 20px;
		}

		.line_shu {
			width: 1.5px;
			height: 12px;
			margin: 0 10px;
			background-color: #535353;
		}

		.img_txt {
			font-size: 14px;
			color: #535353;
			line-height: 16px;
			margin-left: 4px;
		}

		.match_btn {
			position: absolute;
			right: -20px;
			width: 92px;
			height: 32px;
			background: #B4375F;
			border-radius: 5px 5px 5px 5px;
			font-size: 14px;
			color: #FFFFFF;
			margin-right: 17px;
		}

		.match_btn_un {
			position: absolute;
			right: -20px;
			width: 92px;
			height: 32px;
			background: #B8B8B8;
			border-radius: 5px 5px 5px 5px;
			font-size: 14px;
			color: #FFFFFF;
			margin-right: 17px;
		}
	}

	&:nth-child(2n) {
		margin-right: 0;
	}
}

.rich_body {
	background: #FFFFFF;
	margin-top: 20px;

	.sign_body_S {
		padding: 20px;

		.sign_header {
			width: 100%;
			border-bottom: 1px solid #E8E8E8;
			padding-bottom: 20px;
			font-size: 20px;
			color: #212F82;
			line-height: 23px;

			.line_shu2 {
				width: 4px;
				height: 20px;
				background: #212F82;
				margin-right: 14px;
			}
		}
	}

}

/deep/ .el-dialog__header {
	padding: 0;
}

/deep/ .el-dialog__body {
	padding: 0;
}

.sign_image {
	background-image: url('../../../assets/image/img/signDia.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 360px;
	height: 409px;
	position: relative;

	.sing_text {
		font-weight: bold;
		font-size: 28px;
		color: #212F82;
		line-height: 56px;
		margin-top: 25px;
	}

	.sing_text2 {
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		margin-top: 0px;
		margin-bottom: 50px;
	}

	.er_png {
		width: 220px;
		height: 220px;
		border-radius: 10px;
	}

	.close_png {
		position: absolute;
		top: 18px;
		right: 20px;
		width: 28px;
		height: 28px;
	}
}

/deep/ .el-dialog {
	background-color: transparent !important; //弹出层透明
	box-shadow: none !important; /* 可能还需要移除阴影 */
}
</style>
